/* Constractor One Construction and Home Renovation HTML5 Template */

@media only screen and (max-width : 1430px) {

    .projects-section .default-work-column {
        width : 33.333%;
    }
}

@media only screen and (max-width : 1140px) {
    .main-header .header-lower .outer-box {
        padding-right : 0;
    }

    .main-header.header-style-three .nav-outer {
        margin-left  : -15px;
        margin-right : -15px;
        padding-left : 15px;
    }

    .main-menu .navigation > li {
        margin-right : 10px;
    }

    .header-style-two .main-menu .navigation > li {
        margin-right : 0;
        margin-left  : 0;
    }

    .main-menu .navigation > li > a,
    .header-style-two .main-menu .navigation > li > a {
        padding-left  : 10px;
        padding-right : 10px;
    }

    .main-header .header-upper .upper-column {
        margin-left : 20px;
    }

    .tabs-box .left-image-box .upper-curve,
    .tabs-box .left-image-box .lower-curve {
        height : 55%;
    }

    .sortable-masonry .column.big-block {
        width : 50%;
    }

    .sortable-masonry .column.small-block {
        width : 25%;
    }

    .tabs-box .buttons-style-two {
        position : relative;
    }

    .tabs-box .buttons-style-two .tab-buttons .tab-btn {
        display : inline-block !important;
        margin  : 0 5px 10px !important;
        left    : 0 !important;
        right   : 0 !important;
        padding : 6px 20px;
    }

    .tabs-box .buttons-style-two .tab-buttons .tab-btn::before {
        -webkit-transform : skewX(0deg) !important;
        -ms-transform     : skewX(0deg) !important;
        -o-transform      : skewX(0deg) !important;
        -moz-transform    : skewX(0deg) !important;
        transform         : skewX(0deg) !important;
    }

    .tabs-box .man-image-right {
        display : none;
    }

    .tabs-box.tab-style-two .tab {
        margin-right : 0;
        margin-left  : 0;
        padding-left : 0;
    }

    .tabs-box .main-content-box .inner-content {
        padding : 40px 20px;
    }

    .main-header .btn-outer .inq-btn::before {
        -webkit-transform : skewX(0deg);
        -ms-transform     : skewX(0deg);
        -o-transform      : skewX(0deg);
        -moz-transform    : skewX(0deg);
        transform         : skewX(0deg);
    }

    .projects-section .default-work-column {
        width : 50%;
    }

    .single-project .upper-part .main-image {
        margin-right : 0;
    }

    .single-project .upper-part .info-box::before,
    .single-project .upper-part .info-box .white-bar {
        display : none;
    }

    .single-project .upper-part .info-box::after {
        width : 100%;
    }
}

@media only screen and (min-width : 768px) {
    .main-menu .navigation > li > ul,
    .main-menu .navigation > li > ul > li > ul {
        display    : block !important;
        visibility : hidden;
        opacity    : 0;
    }
}

@media only screen and (max-width : 1023px) {

    .main-header.header-style-two {
        position   : relative;
        background : #202E31;
    }

    .main-menu .navigation > li,
    .header-style-two .main-menu .navigation > li {
        margin-right : 5px;
    }

    .main-menu .navigation > li > a,
    .header-style-two .main-menu .navigation > li > a {
        padding-left   : 10px;
        padding-right  : 10px;
        font-weight    : 600;
        text-transform : capitalize;
    }

    .header-style-two .main-menu .navigation > li ul > li > ul {
        left  : auto !important;
        right : 100% !important;
    }

    .main-header .header-top .top-right ul {
        text-align : left;
    }

    .main-header .header-top .top-right ul li {
        display : inline-block;
        float   : none;
        margin  : 0 0 0;
    }

    .main-header .header-upper .upper-right {
        width : 100%;
    }

    .main-header.header-style-two .header-upper .upper-right {
        width : auto;
    }

    .tabs-box .left-image-box .upper-curve,
    .tabs-box .left-image-box .lower-curve {
        display : none;
    }

    .latest-news .outer-box {
        padding-left : 20px;
        margin-left  : 0;
    }

    .latest-news .outer-box .left-image {
        display : none;
    }

    .sortable-masonry .column.big-block {
        width : 100%;
    }

    .sortable-masonry .column.small-block {
        width : 50%;
    }

    .fun-facts .counter-column {
        text-align    : center;
        margin-bottom : 40px;
    }

    .main-header.header-style-two .header-upper .upper-right {
        width : 100%;
    }

    .single-project .upper-part .lower-box .text-right {
        text-align : left !important;
    }

    .sidebar-page-container .content-side {
        margin-bottom : 50px;
    }
}

@media only screen and (max-width : 767px) {


    .projects-section .default-work-column {
        width : 100%;
    }

    .main-header.header-style-two .header-upper .upper-right {
        width : 100%;
    }

    .main-slider,
    .page-title {
        top        : 0;
        margin-top : 0;
    }

    .main-header .nav-outer {
        padding-right : 0;
    }


    .main-header .header-lower .auto-container {
        padding : 0;
    }

    .main-header .nav-outer::before,
    .main-menu .navbar-collapse > .navigation > li > ul,
    .main-menu .navbar-collapse > .navigation > li > ul > li,
    .main-menu .navbar-collapse > .navigation > li > ul > li > a,
    .default-intro-section .content-box::before,
    .fun-facts .content-box::before,
    .main-header .btn-outer .inq-btn::before {
        -webkit-transform : skewX(0deg);
        -ms-transform     : skewX(0deg);
        -o-transform      : skewX(0deg);
        -moz-transform    : skewX(0deg);
        transform         : skewX(0deg);
    }

    .main-header .btn-outer {
        right   : auto;
        left    : 0;
        z-index : 15;
        height  : 50px;
        width   : 170px;
    }

    .header-upper .logo-outer {
        display : block;
        width   : 100%;
    }

    .header-style-two .header-upper .logo-outer {
        text-align    : center;
        margin-bottom : 20px;
    }

    .main-header .header-upper .upper-column {
        margin-left   : 0;
        padding-right : 15px;
        float         : none;
        display       : block;
    }

    .main-header .main-menu {
        padding-top : 0;
        width       : 100%;
        margin      : 0;
    }

    .main-menu .collapse {
        max-height            : 300px;
        overflow              : auto;
        float                 : none;
        width                 : 100%;
        padding               : 0 0;
        border                : 1px solid #FFF;
        margin                : 0;
        -ms-border-radius     : 3px;
        -moz-border-radius    : 3px;
        -webkit-border-radius : 3px;
        -o-border-radius      : 3px;
        border-radius         : 3px;
    }

    .main-menu .collapse.in,
    .main-menu .collapsing {
        padding               : 0;
        border                : 1px solid #FFF;
        margin                : 0 0 15px;
        -ms-border-radius     : 3px;
        -moz-border-radius    : 3px;
        -webkit-border-radius : 3px;
        -o-border-radius      : 3px;
        border-radius         : 3px;
    }


    .main-menu .navbar-header {
        position   : relative;
        float      : none;
        display    : block;
        text-align : right;
        width      : 100%;
        padding    : 8px 15px;
        right      : 0;
        z-index    : 12;
    }

    .main-menu .navbar-header .navbar-toggle {
        display       : inline-block;
        z-index       : 7;
        border        : 1px solid #FFF;
        float         : none;
        margin        : 0;
        border-radius : 0;
        background    : var(--color_main);
    }

    .main-menu .navbar-header .navbar-toggle .icon-bar {
        background : #FFF;
    }

    .main-menu .navbar-collapse > .navigation {
        float      : none !important;
        margin     : 0 !important;
        width      : 100% !important;
        background : #262626;
    }

    .main-menu .navbar-collapse > .navigation > li {
        margin : 0 !important;
        float  : none !important;
        width  : 100%;
    }

    .main-menu .navigation > li > a,
    .main-menu .navigation > li > ul:before {
        border : none;
    }

    .main-menu .navbar-collapse > .navigation > li > a {
        padding : 10px 10px !important;
        border  : none !important;
    }

    .main-menu .navigation li.dropdown > a:after,
    .main-menu .navigation > li.dropdown > a:before,
    .main-menu .navigation > li > ul > li > a::before,
    .main-menu .navigation > li > ul > li > ul > li > a::before,
    .main-menu .navbar-collapse > .navigation > li:before,
    .main-menu .navbar-collapse > .navigation > li:after {
        color     : #FFF !important;
        right     : 15px;
        font-size : 16px;
        display   : none !important;
    }

    .main-menu .navbar-collapse > .navigation > li > ul,
    .main-menu .navbar-collapse > .navigation > li > ul > li > ul {
        position              : relative;
        border                : none;
        float                 : none;
        visibility            : visible;
        opacity               : 1;
        display               : none;
        margin                : 0;
        left                  : auto !important;
        right                 : auto !important;
        top                   : auto !important;
        width                 : 100%;
        background            : #262626;
        -webkit-border-radius : 0;
        -ms-border-radius     : 0px;
        -o-border-radius      : 0px;
        -moz-border-radius    : 0;
        border-radius         : 0;
        transition            : none !important;
        -webkit-transition    : none !important;
        -ms-transition        : none !important;
        -o-transition         : none !important;
        -moz-transition       : none !important;
    }

    .main-menu .navbar-collapse > .navigation > li > ul,
    .main-menu .navbar-collapse > .navigation > li > ul > li > ul {
        border-top : 1px solid rgba(255, 255, 255, 1) !important;
    }

    .main-menu .navbar-collapse > .navigation > li,
    .main-menu .navbar-collapse > .navigation > li > ul > li,
    .main-menu .navbar-collapse > .navigation > li > ul > li > ul > li {
        border-top : 1px solid rgba(255, 255, 255, 1) !important;
        opacity    : 1 !important;
        top        : 0 !important;
        left       : 0 !important;
        visibility : visible !important;
    }

    .main-menu .navbar-collapse > .navigation > li:first-child {
        border : none;
    }

    .main-menu .navbar-collapse > .navigation > li > a,
    .main-menu .navbar-collapse > .navigation > li > ul > li > a,
    .main-menu .navbar-collapse > .navigation > li > ul > li > ul > li > a {
        padding     : 10px 10px !important;
        line-height : 22px;
        color       : #FFF;
        background  : #262626;
        text-align  : left;
    }

    .main-menu .navbar-collapse > .navigation > li > a:hover,
    .main-menu .navbar-collapse > .navigation > li > a:active,
    .main-menu .navbar-collapse > .navigation > li > a:focus {
        background : #262626;
    }

    .main-menu .navbar-collapse > .navigation > li:hover > a,
    .main-menu .navbar-collapse > .navigation > li > ul > li:hover > a,
    .main-menu .navbar-collapse > .navigation > li > ul > li > ul > li:hover > a,
    .main-menu .navbar-collapse > .navigation > li.current > a,
    .main-menu .navbar-collapse > .navigation > li.current-menu-item > a {
        background : #262626;
        color      : var(--color_main) !important;
    }

    .main-menu .navbar-collapse > .navigation li.dropdown .dropdown-btn {
        display : block;
    }

    .main-menu .navbar-collapse > .navigation li.dropdown:after,
    .main-menu .navigation > li > ul:before {
        display : none !important;
    }

    .default-intro-section .content-box {
        padding : 30px 20px;
    }

    .main-slider .tp-caption {
        display : none !important;
    }

    .footer-bottom {
        text-align : center;
    }

    .footer-bottom .pull-left,
    .footer-bottom .pull-right {
        width      : 100%;
        text-align : center;
    }

    .footer-bottom .copyright-text {
        margin-bottom : 10px;
    }

    .footer-bottom .footer-nav ul {
        float : none !important;
    }

    .footer-bottom .footer-nav ul li {
        float   : none;
        display : inline-block;
        margin  : 0 5px 5px;
    }

    .tabs-box .main-content-box .inner-content {
        padding : 30px 20px !important;
    }

    .tabs-box .left-image-box .image-outer {
        padding-right : 0;
    }

    .single-project .upper-part .info-box {
        position  : relative;
        width     : 100%;
        max-width : none;
        padding   : 50px 15px;
    }

    .page-title .text br {
        display : none;
    }

    .error-section .text-column h2 {
        font-size : 32px;
    }
}

@media only screen and (max-width : 599px) {
    .fixed-header .header-lower {
        position : relative !important;
    }

    .main-header .header-upper .upper-column.info-box {
        display : block;
    }

    .main-header .header-top .top-right,
    .main-header .header-top .top-left {
        width : 100%;
    }

    .sidebar-page .comments-area .comment,
    .sidebar-page .comments-area .reply-comment,
    .sidebar-page .about-author .author-desc {
        padding-left : 0;
        margin-left  : 0;
    }

    .sidebar-page .comments-area .comment-box .author-thumb,
    .sidebar-page .about-author .author-thumb {
        position      : relative;
        left          : 0;
        margin-bottom : 20px;
    }

    .main-header .header-upper .upper-column {
        margin-left : 0;
        width       : 100%;
    }

    .page-title {
        padding : 50px 0;
    }

    .page-title h1 {
        font-size : 32px;
    }

    .sec-title h2,
    .main-bxslider h2 {
        font-size : 18px;
    }

    .main-header .header-upper .upper-column {
        padding-right : 0;
    }

    .sortable-masonry .column.small-block {
        width : 100%;
    }

    .search-popup .search-form fieldset input[type="search"],
    .search-popup .search-form fieldset input[type="text"] {
        display       : block;
        padding       : 15px 20px;
        font-size     : 20px;
        border-radius : 5px 5px 0 0;
    }

    .search-popup .search-form fieldset input[type="submit"] {
        display       : block;
        width         : 100%;
        position      : relative;
        border-radius : 0 0 5px 5px;
    }

    .services-section .outer-box {
        padding      : 40px 20px;
        margin-right : 0;
    }

    .services-section .outer-box .man-image,
    .default-two-column .image-box::before {
        display : none;
    }

    .default-two-column .column {
        margin-bottom : 0;
    }

    .default-two-column .text-column .text,
    .default-two-column .image-box {
        padding-left : 0 !important;
        margin-right : 0 !important;
    }

    .default-blog-post .date-box {
        top : -75px;
    }

}

@media only screen and (max-width : 499px) {
    .team-member .lower-part {
        bottom : 0;
    }

    .default-work-column .caption-layer .options li,
    .team-member .caption-layer .options li {
        display    : block;
        width      : 100% !important;
        float      : none;
        text-align : center;
    }

    .testimonials-section .testi-box {
        padding-left : 0;
    }

    .testimonials-section .testi-box .author-thumb {
        position      : relative;
        width         : 100%;
        height        : auto;
        margin-bottom : 20px;
    }

    .testimonials-section .testi-box .info-box {
        padding : 15px 10px;
    }

    .testimonials-section .testi-box .info-box .left-curve,
    .testimonials-section .testi-box .info-box .right-curve {
        display : none;
    }

    .tabs-box .tab-buttons .tab-btn,
    .tabs-box .buttons-style-two .tab-buttons .tab-btn {
        display    : block !important;
        margin     : 15px;
        text-align : center;
    }

    .tabs-box .tab-buttons .tab-btn::before {
        -webkit-transform : skewX(0deg);
        -ms-transform     : skewX(0deg);
        -o-transform      : skewX(0deg);
        -moz-transform    : skewX(0deg);
        transform         : skewX(0deg);
    }
}