:root {
    --color_main : rgba(0, 204, 255, 255)
}

.masonry_grid {
    display               : grid;
    grid-gap              : 20px;
    grid-template-columns : auto auto auto;
    grid-auto-rows        : 20px;
    margin-bottom         : 20px;

    .grid_item {
        background    : white;
        padding       : 10px;
        border-radius : 5px;
    }

    li {
        padding-left  : 10px;
        margin-bottom : 5px;
        color         : #131313;
        position      : relative;
    }

    li:before {
        content     : '\f105';
        font-family : 'FontAwesome';
        position    : absolute;
        left        : 0px;
        top         : -1px;
        width       : 15px;
        line-height : 24px;
        display     : block;
        color       : #2B3338;
    }
}

.d-none {
    display : none;
}

div.service_image {
    padding : 10px;

    img {
        width  : 100%;
        height : 450px;
        border : solid 2px #e8e8e8;
    }

    div.caption {
        width      : 100%;
        background : white;
        padding    : 5px 10px;
        text-align : center;
    }
}

div.slider_container {
    z-index   : -100;
    height    : calc(100vh - 50px);
    position  : relative;
    overflow  : hidden;
    max-width : 100vw;

    /* Next & previous buttons */
    div.prev, div.next {
        cursor        : pointer;
        position      : absolute;
        top           : 50%;
        width         : auto;
        margin-top    : -22px;
        padding       : 16px;
        color         : white;
        font-weight   : bold;
        font-size     : 18px;
        transition    : 0.6s ease;
        border-radius : 0 3px 3px 0;
        user-select   : none;
    }

    /* Position the "next button" to the right */
    div.next {
        right         : 0;
        border-radius : 3px 0 0 3px;
    }

    /* On hover, add a black background color with a little bit see-through */
    div.prev:hover, div.next:hover {
        background-color : rgba(0, 0, 0, 0.8);
    }

    /* The dots/bullets/indicators */
    .dot {
        cursor           : pointer;
        height           : 15px;
        width            : 15px;
        margin           : 0 2px;
        background-color : #bbb;
        border-radius    : 50%;
        display          : inline-block;
        transition       : background-color 0.6s ease;
    }

    .active, .dot:hover {
        background-color : #717171;
    }


    div.slider {
        display            : none;
        animation-name     : fade;
        animation-duration : 1.5s;

        div.bg_image {
            position          : absolute;
            top               : 0;
            left              : 0;
            right             : 0;
            bottom            : 0;
            background-size   : 800px 600px;
            background-repeat : repeat;
        }

        /* Number text (1/3 etc) */
        .number_text {
            color     : #f2f2f2;
            font-size : 12px;
            padding   : 8px 12px;
            position  : absolute;
            top       : 0;
        }


        .text {
            color      : #f2f2f2;
            padding    : 8px 12px;
            position   : absolute;
            top        : 0;
            left       : 0;
            right      : 0;
            bottom     : 0;
            width      : 100%;
            text-align : right;
            background : rgba(16, 71, 83, 0.55);

            .caption {
                font-size     : 25px;
                position      : absolute;
                bottom        : 10px;
                right         : 10px;
                color         : white;
                background    : rgba(0, 0, 0, 0.6);
                padding       : 15px 10px;
                border-radius : 7px;
                font-weight   : 700;
                text-align    : center;

                span {
                    font-weight : 500;
                    display     : block;
                    max-width   : calc(100vw / 2);
                    font-size   : 15px;
                    margin-top  : 20px;
                }
            }
        }
    }

    @keyframes fade {
        from {
            opacity : .4
        }
        to {
            opacity : 1
        }
    }
}

@media only screen and (max-width : 768px) {
    div.slider_container {
        div.slider {
            div.bg_image {
                background-size : 450px 300px;
            }

            .text {
                text-align : left;

                .caption {
                    font-size : 15px;

                    span {
                        max-width : calc(100% - 40px);
                        font-size : 13px;
                    }
                }
            }
        }
    }
}

.team-member .inner-box .image-box img {
    max-width               : 60% !important;
    height                  : auto;
    border-bottom           : solid 3px var(--color_main);
    margin-bottom           : 10px;
    border-top-right-radius : 15px;
    border-top-left-radius  : 15px;
}
