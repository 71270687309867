/* Constractor One Construction and Home Renovation HTML5 Template */

/************ TABLE OF CONTENTS ***************
1. Fonts
2. Reset
3. Global
4. Main Header / Style One / Style Two
5. Main Slider / Style One / Style Two
6. Services Style One
7. Intro Section
8. What We Do
9. Latest Works
10. Our Team Section
11. Testimonials Section
12. Latest News
13. Sponsors Carousel
14. Main Footer
15. Fact Counter
16. Masonry Gallery
17. Default Two COlumn
18. Services Style Two
19. Projects Section
20. Single Project
21. Blog Classic
22. Sidebar
23. Sidebar Widgets
24. Blog Two Column
25. Blog Single
26. Comments Area
27. Reply Comment Form
28. Styled pagination
29. Contact Section
30. Map / Google Map
31. 404 Page


**********************************************/

@import url(https://fonts.googleapis.com/css?family=Roboto:400,400italic,500,500italic,700,700italic,900,300,300italic);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,300,500,600,700);

@import url('font-awesome.css');
@import url('flaticon.css');
@import url('animate.css');
@import url('owl.css');
@import url('jquery.fancybox.css');
@import url('hover.css');

/***

====================================================================
	Reset
====================================================================

 ***/
* {
    margin: 0px;
    padding: 0px;
    border: none;
    outline: none;
}

/***

====================================================================
	Global Settings
====================================================================

 ***/

body {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #999;
    line-height: 1.8em;
    font-weight: 400;
    background: #FFF;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    -webkit-font-smoothing: antialiased;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: var(--color_main);
}

a:hover, a:focus, a:visited {
    text-decoration: none;
    outline: none;
}

h1, h2, h3, h4, h5, h6 {
    position: relative;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    margin: 0px;
    background: none;
    line-height: 1.6em;
}

input, button, select, textarea {
    font-family: 'Roboto', sans-serif;
}

p {
    position: relative;
    line-height: 1.8em;
}

.strike-through {
    text-decoration: line-through;
}

.auto-container {
    position: static;
    max-width: 1200px;
    padding: 0px 15px;
    margin: 0 auto;
}

.medium-container {
    max-width: 850px;
}

.page-wrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
    min-width: 300px;

}

ul, li {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.theme-btn {
    display: inline-block;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.centered {
    text-align: center;
}

.anim-3, .anim-3-all * {
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.anim-5, .anim-5-all * {
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.anim-7, .anim-7-all * {
    transition: all 0.7s ease;
    -moz-transition: all 0.7s ease;
    -webkit-transition: all 0.7s ease;
    -ms-transition: all 0.7s ease;
    -o-transition: all 0.7s ease;
}

.btn-theme-light {
    position: relative;
    padding: 10px 25px;
    line-height: 24px;
    text-transform: uppercase;
    background: none;
    color: #FFF !important;
    font-size: 12px;
    border: 1px solid #FFF !important;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.btn-theme-light:hover {
    background: var(--color_main);
    color: #FFF !important;
    border-color: var(--color_main) !important;
}

.btn-theme-grey {
    position: relative;
    padding: 10px 25px;
    line-height: 24px;
    text-transform: uppercase;
    background: none;
    color: #555 !important;
    font-size: 12px;
    border: 1px solid #999 !important;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.btn-theme-grey:hover {
    background: #999;
    color: #FFF !important;
    border-color: #999 !important;
}

.btn-style-one {
    position: relative;
    padding: 15px 32px;
    line-height: 24px;
    text-transform: uppercase;
    background: #202E31;
    color: #FFF !important;
    font-size: 16px;
    font-weight: 700;
    outline: 2px solid #FFF;
    outline-offset: -6px;
    font-family: 'Poppins', sans-serif;
}

.btn-style-one:hover {
    border-color: #FFF !important;
    background: var(--color_main);
    outline: 2px solid #FFF;
    outline-offset: -6px;
}

.btn-style-two {
    position: relative;
    padding: 15px 32px;
    line-height: 24px;
    text-transform: uppercase;
    background: var(--color_main);
    color: #FFF !important;
    font-size: 16px;
    font-weight: 700;
    outline: 2px solid #FFF;
    outline-offset: -6px;
    font-family: 'Poppins', sans-serif;
}

.btn-style-two:hover {
    border-color: #FFF !important;
    background: #202E31;
    outline: 2px solid #FFF;
    outline-offset: -6px;
}

.btn-style-three {
    position: relative;
    padding: 13px 30px;
    line-height: 24px;
    text-transform: uppercase;
    background: none;
    color: #FFF !important;
    font-size: 16px;
    font-weight: 700;
    border: 2px solid #FFF;
    font-family: 'Poppins', sans-serif;
}

.btn-style-three:hover {
    border-color: var(--color_main) !important;
    color: var(--color_main) !important;
}


.theme-btn .fa {
    font-size: 16px;
}

.theme-btn .icon-left {
    padding-right: 10px;
    font-weight: normal;
}

.theme-btn .icon-right {
    padding-left: 10px;
    font-weight: normal;
}

.theme_color {
    color: var(--color_main) !important;
}

.light-font {
    font-weight: 300;
}

.regular-font {
    font-weight: 400;
}

.semibold-font {
    font-weight: 600;
}

.bold-font {
    font-weight: 700;
}

.padd-top-10 {
    padding-top: 10px !important;
}

.padd-top-20 {
    padding-top: 20px !important;
}

.padd-top-30 {
    padding-top: 30px !important;
}

.padd-top-40 {
    padding-top: 40px !important;
}

.padd-top-50 {
    padding-top: 50px !important;
}

.padd-top-60 {
    padding-top: 60px !important;
}

.padd-top-70 {
    padding-top: 70px !important;
}

.padd-left-10 {
    padding-left: 10px !important;
}

.padd-left-20 {
    padding-left: 20px !important;
}

.padd-left-30 {
    padding-left: 30px !important;
}

.padd-left-40 {
    padding-left: 40px !important;
}

.padd-left-50 {
    padding-left: 50px !important;
}

.padd-left-60 {
    padding-left: 60px !important;
}

.padd-left-70 {
    padding-left: 70px !important;
}

.padd-bott-10 {
    padding-bottom: 10px !important;
}

.padd-bott-20 {
    padding-bottom: 20px !important;
}

.padd-bott-30 {
    padding-bottom: 30px !important;
}

.padd-bott-40 {
    padding-bottom: 40px !important;
}

.padd-bott-50 {
    padding-bottom: 50px !important;
}

.padd-bott-60 {
    padding-bottom: 60px !important;
}

.padd-bott-70 {
    padding-bottom: 70px !important;
}

.padd-bott-90 {
    padding-bottom: 90px !important;
}

.padd-bott-100 {
    padding-bottom: 100px !important;
}

.padd-right-10 {
    padding-right: 10px !important;
}

.padd-right-20 {
    padding-right: 20px !important;
}

.padd-right-30 {
    padding-right: 30px !important;
}

.padd-right-40 {
    padding-right: 40px !important;
}

.padd-right-50 {
    padding-right: 50px !important;
}

.padd-right-60 {
    padding-right: 60px !important;
}

.padd-right-70 {
    padding-right: 70px !important;
}

.margin-top-10 {
    margin-top: 10px !important;
}

.margin-top-20 {
    margin-top: 20px !important;
}

.margin-top-30 {
    margin-top: 30px !important;
}

.margin-top-40 {
    margin-top: 40px !important;
}

.margin-top-50 {
    margin-top: 50px !important;
}

.margin-top-60 {
    margin-top: 60px !important;
}

.margin-top-70 {
    margin-top: 70px !important;
}

.margin-bott-10 {
    margin-bottom: 10px !important;
}

.margin-bott-20 {
    margin-bottom: 20px !important;
}

.margin-bott-30 {
    margin-bottom: 30px !important;
}

.margin-bott-40 {
    margin-bottom: 40px !important;
}

.margin-bott-50 {
    margin-bottom: 50px !important;
}

.margin-bott-60 {
    margin-bottom: 60px !important;
}

.margin-bott-70 {
    margin-bottom: 70px !important;
}

.no-padd-bottom {
    padding-bottom: 0px !important;
}

.no-padd-top {
    padding-top: 0px !important;
}

.no-margin-bottom {
    margin-bottom: 0px !important;
}

.no-margin-top {
    margin-bottom: 0px !important;
}

.no-padd-left {
    padding-left: 0px !important;
}

.no-padd-right {
    padding-right: 0px !important;
}

.bg-lightgrey {
    background-color: #F6F6F6 !important;
}

.no-bg {
    background: none !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.preloader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: #FFF;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../images/icons/preloader.gif);
}

/***

====================================================================
	Scroll To Top style
====================================================================

***/

.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 10px;
    width: 40px;
    height: 40px;
    color: #FFF;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 48px;
    text-align: center;
    z-index: 100;
    cursor: pointer;
    display: none;
}

.scroll-to-top:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 40px;
    height: 40px;
    background: var(--color_main);
    border: 1px solid var(--color_main);
    border-radius: 20px 0px 20px 20px;
    -ms-border-radius: 20px 0px 20px 20px;
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    transform: rotate(135deg);

}

.scroll-to-top span {
    position: relative;
    display: block;
    z-index: 1;
}

.scroll-to-top:hover {
    color: var(--color_main);
}

.scroll-to-top:hover:before {
    background: #FFF;
}

/***

====================================================================
	Main Header style
====================================================================

***/

.main-header {
    position: relative;
    left: 0px;
    top: 0px;
    z-index: 999;
    width: 100%;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.main-header .header-top {
    position: relative;
    background: #202E31;
    color: #FFF;
    z-index: 5;
    font-family: 'Roboto', sans-serif;
}

.main-header.header-style-two {
    position: absolute;
}

.main-header.header-style-two .header-top {
    background: #202E31;
}

.main-header .header-top .top-left {
    position: relative;
    float: left;
    padding: 5px 0px;
    color: #C9C9C9;
    font-size: 12px;
}

.main-header .header-top .top-left:before {
    content: '';
    position: absolute;
    right: 0px;
    top: 0px;
    width: 1200px;
    height: 100%;
    background: #1A2628;
    -webkit-transform: skewX(-25deg);
    -ms-transform: skewX(-25deg);
    -o-transform: skewX(-25deg);
    -moz-transform: skewX(-25deg);
    transform: skewX(-25deg);
}

.main-header .header-top ul {
    position: relative;
    z-index: 1;
}

.main-header .header-top ul li {
    position: relative;
    float: left;
    margin-right: 30px;
}

.main-header .header-top .top-right ul li {
    margin-right: 0px;
    padding: 0px 15px;
}

.main-header .header-top .top-right ul li:before {
    content: '|';
    position: absolute;
    right: -1px;
    top: -3px;
    color: #FFF;
    font-size: 10px;
}

.main-header .header-top .top-right ul li:last-child:before {
    display: none;
}

.main-header .header-top .top-right ul li:last-child {
    padding-right: 0px;
}

.main-header .header-top ul li a {
    position: relative;
    display: block;
    line-height: 24px;
    color: #FFF;
    font-size: 10px;
    text-transform: uppercase;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.main-header.header-style-two .header-top .top-left ul li a {
    color: #819498;
    text-transform: capitalize;
}

.main-header .header-top ul li .icon {
    position: relative;
    top: 1px;
    font-size: 14px;
    padding-right: 5px;
}

.main-header .header-top .top-left ul li .icon {
    padding-right: 10px;
}

.main-header .header-top ul li a:hover {
    color: var(--color_main) !important;
}

.main-header .header-top .top-right {
    position: relative;
    padding: 5px 0px;
    float: right;
}

.main-header .header-upper {
    position: relative;
    padding: 25px 0px;
    background-color: #FFF;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    z-index: 5;
}

.main-header.header-style-two .header-upper {
    background: none;
}

.main-header .header-upper .upper-right {
    position: relative;
}

.main-header .header-upper .upper-column {
    position: relative;
    float: left;
    min-width: 80px;
    margin-left: 40px;
    padding-top: 22px;
}

.main-header .info-box {
    position: relative;
    padding-left: 50px;
}

.main-header .info-box .icon-box {
    position: absolute;
    left: 0px;
    top: 12px;
    width: 50px;
    line-height: 60px;
    color: #4A5C7A;
    font-size: 36px;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.main-header .info-box:hover .icon-box {
    color: var(--color_main);
}

.main-header .info-box li {
    position: relative;
    line-height: 20px;
    margin: 0px 0px;
    color: #202E31;
    text-transform: uppercase;
}

.main-header .info-box li .small-title {
    font-size: 10px;
    letter-spacing: 1px;
}

.main-header .info-box li a {
    color: #202E31;
}

.main-header .info-box strong {
    font-weight: 700;
    color: #555;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
}

.main-header .header-lower {
    position: relative;
    left: 0px;
    top: 0px;
    width: 100%;
    background: none;
    z-index: 5;
}

.main-header .header-lower .auto-container {
    padding: 0px 30px;
}

.main-header.header-style-three .header-lower {
    background: none;
}

.main-header .nav-outer {
    position: relative;
    padding-right: 250px;
}

.main-header.header-style-two .nav-outer {
    margin-top: 15px;
}

.main-header .nav-outer:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: #202E31;
    -webkit-transform: skewX(-25deg);
    -ms-transform: skewX(-25deg);
    -o-transform: skewX(-25deg);
    -moz-transform: skewX(-25deg);
    transform: skewX(-25deg);
}

.main-header.header-style-two .nav-outer:before {
    background: none;
}

.main-menu {
    position: relative;
    z-index: 1;
}

.main-menu .navbar-collapse {
    padding: 0px;
}

.main-menu .navigation {
    position: relative;
    margin: 0px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
}

.main-menu .navigation > li {
    position: relative;
    float: left;
    padding: 0px;
}

.main-menu .navigation > li:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: #202E31;
    -webkit-transform: skewX(-25deg);
    -ms-transform: skewX(-25deg);
    -o-transform: skewX(-25deg);
    -moz-transform: skewX(-25deg);
    transform: skewX(-25deg);
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.bounce-in-header .main-menu .navigation > li {
    margin-right: 0px;
}

.main-menu .navigation > li > a {
    position: relative;
    z-index: 1;
    display: block;
    padding: 13px 25px;
    font-size: 14px;
    color: #FFF;
    line-height: 24px;
    border-top: none;
    text-transform: uppercase;
    opacity: 1;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.header-style-two .navigation > li:before {
    display: none;
}

.header-style-two .main-menu .navigation > li > a {
    color: #FFF;
}

.header-style-two .main-menu .navigation > li.current > a {
    color: var(--color_main);
}

.main-menu .navigation > li:hover:before,
.main-menu .navigation > li.current:before,
.main-menu .navigation > li.current-menu-item:before {
    background: var(--color_main);
}

.main-menu .navigation > li:hover > a,
.main-menu .navigation > li.current > a,
.main-menu .navigation > li.current-menu-item > a {
    border-color: var(--color_main);
    opacity: 1;
}

.main-menu .navigation > li:hover > a:after {
    opacity: 1;
}

.main-menu .navigation > li > ul:before {
    content: '';
    position: absolute;
    left: 0px;
    top: -5px;
    display: block;
    width: 100%;
    height: 5px;
}

.main-menu .navigation > li > ul {
    position: absolute;
    left: -15px;
    margin-top: 5px;
    top: 110%;
    width: 220px;
    padding: 0px;
    z-index: 100;
    display: none;
    -webkit-transform: skewX(-25deg);
    -ms-transform: skewX(-25deg);
    -o-transform: skewX(-25deg);
    -moz-transform: skewX(-25deg);
    transform: skewX(-25deg);
    background: #202E31;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
}

.main-menu .navigation > li > ul.from-right {
    left: auto;
    right: 0px;
}

.main-menu .navigation > li > ul > li {
    position: relative;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.main-menu .navigation > li > ul > li:last-child {
    border-bottom: none;
}

.main-menu .navigation > li > ul > li > a {
    position: relative;
    display: block;
    padding: 10px 15px 10px 20px;
    line-height: 22px;
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
    color: #FFF;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -webkit-transform: skewX(25deg);
    -ms-transform: skewX(25deg);
    -o-transform: skewX(25deg);
    -moz-transform: skewX(25deg);
    transform: skewX(25deg);
}

.main-menu .navigation > li > ul > li:hover > a,
.main-menu .navigation > li > ul > li:hover {
    color: var(--color_main);
    border-color: var(--color_main);
}

.main-menu .navigation > li.dropdown:hover > ul {
    visibility: visible;
    opacity: 1;
    top: 100%;
}

.main-menu .navbar-collapse > ul li.dropdown .dropdown-btn {
    position: absolute;
    right: 10px;
    top: 6px;
    width: 34px;
    height: 30px;
    border: 1px solid #FFF;
    background: url(../images/icons/submenu-icon.png) center center no-repeat;
    background-size: 20px;
    cursor: pointer;
    z-index: 5;
    display: none;
}

.main-header .btn-outer {
    position: absolute;
    right: 12px;
    top: 0px;
    width: 210px;
    height: 100%;
    background: var(--color_main);
}

.main-header.header-style-two .btn-outer {
    background: none;
}

.main-header .btn-outer .inq-btn {
    position: relative;
    display: block;
    line-height: 24px;
    text-align: center;
    padding: 13px 0px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #FFF;
    text-transform: uppercase;
}

.main-header .btn-outer .inq-btn .txt {
    position: relative;
    z-index: 1;
}

.main-header .btn-outer .inq-btn:before {
    content: '';
    position: absolute;
    left: 13px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: var(--color_main);
    -webkit-transform: skewX(-25deg);
    -ms-transform: skewX(-25deg);
    -o-transform: skewX(-25deg);
    -moz-transform: skewX(-25deg);
    transform: skewX(-25deg);
}


/***

====================================================================
	Search Popup
====================================================================

***/

.search-popup {
    position: fixed;
    font-family: 'Roboto', sans-serif;
    left: 0px;
    top: -100%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.80);
    z-index: 9999;
    visibility: hidden;
    opacity: 0;
    overflow: auto;
    transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -webkit-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
}

.search-popup.popup-visible {
    top: 0;
    visibility: visible;
    opacity: 1;
}

.search-popup .close-search {
    position: absolute;
    right: 15px;
    top: 10px;
    font-size: 24px !important;
    color: #FFF;
    cursor: pointer;
    z-index: 5;
}

.search-popup .close-search .fa {
    font-size: 24px !important;
}

.search-popup .close-search:hover {
    opacity: 0.70;
}

.search-popup .search-form {
    position: relative;
    padding: 0px 15px 50px;
    padding-top: 130px;
    max-width: 1024px;
    margin: 0 auto;
}

.search-popup .search-form fieldset {
    position: relative;
    border: 7px solid rgba(255, 255, 255, 0.50);
    -webkit-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
}

.search-popup .search-form fieldset input[type="search"],
.search-popup .search-form fieldset input[type="text"] {
    position: relative;
    height: 70px;
    padding: 20px 220px 20px 30px;
    background: #FFF;
    line-height: 30px;
    font-size: 24px;
    color: #233145;
    -webkit-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
}

.search-popup .search-form fieldset input[type="submit"] {
    position: absolute;
    display: block;
    right: 0px;
    top: 0px;
    text-align: center;
    width: 220px;
    height: 70px;
    padding: 20px 10px 20px 10px;
    color: #FFF !important;
    background: var(--color_main);
    line-height: 30px;
    font-size: 20px;
    text-transform: uppercase;
    -moz-border-radius: 0px 7px 7px 0px;
    -ms-border-radius: 0px 7px 7px 0px;
    -o-border-radius: 0px 7px 7px 0px;
    -webkit-border-radius: 0px 7px 7px 0px;
    border-radius: 0px 7px 7px 0px;
}

.search-popup .search-form fieldset input[type="submit"]:hover {
    background: #334551;
}

.search-popup h3 {
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    color: #FFF;
    margin-bottom: 20px;
    text-align: center;
}

.search-popup .recent-searches {
    font-size: 16px;
    color: #FFF;
    text-align: center;
}

.search-popup .recent-searches li {
    display: inline-block;
    margin: 0px 10px 10px 0px;
}

.search-popup .recent-searches li a {
    display: block;
    line-height: 24px;
    border: 1px solid #FFF;
    padding: 7px 15px;
    color: #FFF;
    border-radius: 3px;
}

.search-popup .recent-searches li a:hover {
    color: var(--color_main);
    border-color: var(--color_main);
}


/***

====================================================================
	Main Slider style
====================================================================

***/

.main-slider {
    position: relative;
    width: 100%;
    overflow: hidden;
    top: -50px;
    z-index: 10;
    color: #FFF;
}

.main-slider.style-two {
    top: 0px;
}

.main-slider .text {
    font-weight: 400;
    font-size: 16px;
}

.main-slider h1 {
    line-height: 1.2em;
    font-size: 70px;
    font-weight: 700;
    text-transform: uppercase;
}

.main-slider h2 {
    line-height: 1.2em;
    font-weight: 700;
    font-size: 36px;
}

.main-slider h3 {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 700;
    color: #FFF;
}

.main-slider .grey-color {
    color: #202E31;
}

.main-slider.style-one .tp-bullets {
    display: none;
}

.main-slider .tp-bullets.preview4 .bullet {
    border-color: var(--color_main) !important;
    margin: 0px 5px;
}

.main-slider .tparrows.preview3 {
    background: none !important;
    width: 40px;
}

.main-slider .tparrows.preview3::after {
    top: 20px;
    background: #26393E;
    color: #FFF;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    width: 40px;
    border-radius: 3px;
}

.main-slider .tparrows.tp-leftarrow.preview3::after {
    content: '\f10e';
    font-family: 'Flaticon';
}

.main-slider .tparrows.tp-rightarrow.preview3::after {
    content: '\f102';
    font-family: 'Flaticon';
}


.main-slider .tparrows.preview3.tp-leftarrow {
    left: 10px !important;
}

.main-slider .tparrows.preview3.tp-rightarrow {
    right: 10px !important;
}

.main-slider .tparrows.preview3:hover::after {
    background-color: #FAC955;
    color: #FFF !important;
}

.main-slider .tparrows.preview3 .tp-arr-iwrapper {
    visibility: hidden;
    opacity: 0;
}

.main-slider.style-one:after {
    content: '';
    position: relative;
    display: block;
    width: 100%;
    height: 13px;
}

.main-slider .tp-bannertimer {
    display: none !important;
}

/***

====================================================================
	Section  Title
====================================================================

***/

.small-container {
    max-width: 800px;
    margin: 0 auto;
}

.sec-title {
    position: relative;
    margin-bottom: 60px;
}

.sec-title h2 {
    position: relative;
    font-size: 34px;
    font-weight: 700;
    color: #202E31;
    margin-bottom: 5px !important;
    text-transform: uppercase;
    letter-spacing: 1px;
    word-spacing: 2px;
    -webkit-text-shadow: 5px 5px 1px #F5F5F5;
    -ms-text-shadow: 5px 5px 1px #F5F5F5;
    -o-text-shadow: 5px 5px 1px #F5F5F5;
    -moz-text-shadow: 5px 5px 1px #F5F5F5;
    text-shadow: 5px 5px 1px #F5F5F5;
}

.sec-title h3 {
    position: relative;
    font-size: 24px;
    color: #999;
}

.sec-title .text {
    position: relative;
    font-size: 16px;
    color: #A9A9A9;
}

/***

====================================================================
	Services Style One
====================================================================

***/

.services-style-one {
    position: relative;
    padding: 90px 0px 70px;
}

.services-style-one .default-service-column {
    position: relative;
    margin-bottom: 30px;
    text-align: center;
}

.default-service-column {
    position: relative;
}

.default-service-column .inner-box {
    position: relative;
    border: 1px solid #E0E0E0;
    max-width: 420px;
    margin: 0 auto;
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
}

.default-service-column .inner-box:hover {
    -webkit-box-shadow: 0px 0px 7px 5px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0px 0px 7px 5px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0px 0px 7px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 7px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 7px 5px rgba(0, 0, 0, 0.05);
}

.default-service-column .inner-most {
    position: relative;
    overflow: hidden;
}

.default-service-column .image-box {
    position: relative;
}

.default-service-column .image-box img {
    display: block;
    width: 100%;
}

.default-service-column .lower-part {
    position: relative;
    padding-top: 20px;
    margin-top: -20px;
}

.default-service-column .lower-part .left-curve {
    position: absolute;
    left: 0px;
    top: -7px;
    width: 53.1%;
    height: 90px;
    background: #FFF;
    border-top: 10px solid transparent;
    -webkit-transform: rotate(-12deg);
    -ms-transform: rotate(-12deg);
    -o-transform: rotate(-12deg);
    -moz-transform: rotate(-12deg);
    transform: rotate(-12deg);
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
}

.default-service-column .lower-part .right-curve {
    position: absolute;
    right: 0px;
    top: -7px;
    width: 53.1%;
    height: 90px;
    background: #FFF;
    border-top: 10px solid transparent;
    -webkit-transform: rotate(12deg);
    -ms-transform: rotate(12deg);
    -o-transform: rotate(12deg);
    -moz-transform: rotate(12deg);
    transform: rotate(12deg);
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
}

.default-service-column:hover .lower-part .left-curve,
.default-service-column:hover .lower-part .right-curve {
    border-top-color: var(--color_main);
}

.default-service-column .content {
    position: relative;
    padding: 0px 40px 20px;
    z-index: 1;
}

.default-service-column .content h3 {
    position: relative;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 5px;
    color: #202E31;
    font-weight: 700;
}

.default-service-column .content .text {
    position: relative;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 10px;
    color: #202E31;
}

.default-service-column .content .more-link {
    position: relative;
}

.default-service-column .content .read-more {
    position: relative;
    display: inline-block;
    line-height: 20px;
    font-weight: 700;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    border-bottom: 1px solid transparent;
    color: var(--color_main);
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
}

.default-service-column .content .read-more:hover,
.default-service-column:hover .content .read-more {
    border-color: var(--color_main);
}

/***

====================================================================
	Default Intro Section
====================================================================

***/

.default-intro-section {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
}

.default-intro-section:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: var(--color_main);
}

.default-intro-section .content-box {
    position: relative;
    padding: 45px 70px 40px 70px;
    color: #FFF;
}

.default-intro-section .content-box:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 1500px;
    height: 101%;
    background: #202E31;
    -webkit-transform: skewX(-25deg);
    -ms-transform: skewX(-25deg);
    -o-transform: skewX(-25deg);
    -moz-transform: skewX(-25deg);
    transform: skewX(-25deg);
}

.default-intro-section h3 {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
}

.default-intro-section .text {
    font-size: 14px;
}

/***

====================================================================
	What We Do Section
====================================================================

***/

.what-we-do {
    position: relative;
    padding: 90px 0px;
}

.what-we-do .auto-container {
    position: relative;
    z-index: 1;
}

.tabs-box {
    position: relative;
}

.tabs-box .buttons-style-one {
    position: relative;
    margin-bottom: 50px;
}

.tabs-box .buttons-style-two {
    position: absolute;
    left: 0px;
    top: 0px;
    margin-bottom: 50px;
}

.tabs-box .tab-buttons {
    position: relative;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

.tabs-box .tab-buttons .tab-btn {
    position: relative;
    display: inline-block;
    margin: 0px 4px 5px;
    color: #B0B0B0;
    padding: 10px 35px 10px 20px;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    line-height: 28px;
    font-weight: 500;
}

.tabs-box .buttons-style-two .tab-buttons .tab-btn {
    display: block;
    margin: 0px 0px 10px 0px;
    color: #202E31;
    text-align: left;
    z-index: 5;
}

.tabs-box .buttons-style-two .tab-buttons .tab-btn:nth-child(1) {
    left: -9px;
}

.tabs-box .buttons-style-two .tab-buttons .tab-btn:nth-child(2) {
    left: -26px;
}

.tabs-box .buttons-style-two .tab-buttons .tab-btn:nth-child(3) {
    left: -43px;
}

.tabs-box .buttons-style-two .tab-buttons .tab-btn:nth-child(4) {
    left: -47px;
}

.tabs-box .buttons-style-two .tab-buttons .tab-btn:nth-child(5) {
    left: -31px;
}

.tabs-box .tab-buttons .tab-btn:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    border: 1px solid #D0D0D0;
    background: #FFF;
    -webkit-transform: skewX(-25deg);
    -ms-transform: skewX(-25deg);
    -o-transform: skewX(-25deg);
    -moz-transform: skewX(-25deg);
    transform: skewX(-25deg);
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
}

.tabs-box .buttons-style-two .tab-buttons .tab-btn:before {
    -webkit-transform: skewX(-15deg);
    -ms-transform: skewX(-15deg);
    -o-transform: skewX(-15deg);
    -moz-transform: skewX(-15deg);
    transform: skewX(-15deg);
}

.tabs-box .buttons-style-two .tab-buttons .tab-btn:nth-child(4):before,
.tabs-box .buttons-style-two .tab-buttons .tab-btn:nth-child(5):before {
    -webkit-transform: skewX(15deg);
    -ms-transform: skewX(15deg);
    -o-transform: skewX(15deg);
    -moz-transform: skewX(15deg);
    transform: skewX(15deg);
}

.tabs-box .tab-buttons .tab-btn:hover:before,
.tabs-box .tab-buttons .tab-btn.active-btn:before {
    background: var(--color_main);
    border-color: var(--color_main);
}

.tabs-box .tab-buttons .tab-btn:hover,
.tabs-box .tab-buttons .tab-btn.active-btn {
    color: #FFF;
}

.tabs-box .tab-buttons .tab-btn .txt {
    position: relative;
}

.tabs-box .tab-buttons .tab-btn .icon {
    position: relative;
    font-size: 22px;
    padding-right: 10px;
}

.tabs-box .tab {
    display: none;
    box-shadow: 0px 5px 15px 1px rgba(2, 2, 2, 0.10);
}

.tabs-box .tab.active-tab {
    display: block;
}

.tabs-box .left-image-box,
.tabs-box .main-content-box,
.tabs-box .how-works-box {
    position: relative;
    padding: 0px !important;
}

.tabs-box .left-image-box .image-outer {
    position: relative;
    padding-right: 40px;
}

.tabs-box .left-image-box .image img {
    display: block;
    width: 100%;
}

.tabs-box .tab .outer {
    position: relative;
    background: #FFF;
    overflow: hidden;
    border: 1px solid #F4F4F4;
}

.tabs-box.tab-style-two .tab {
    margin-left: 180px;
    margin-right: 140px;
    padding-left: 20px;
}

.tabs-box.tab-style-two .tab .outer {
    border: 1px solid #F0F0F0;
    border-left: none;
}

.tabs-box .main-content-box .inner-content {
    position: relative;
    padding: 50px 50px 20px 40px;
    padding-left: 0px;
    background: #FFF;
    overflow: hidden;
}

.tabs-box .main-content-box .inner-content .image-box {
    position: relative;
    margin-bottom: 20px;
}

.tabs-box .main-content-box .inner-content .image-box img {
    display: block;
    width: 100%;
}

.tabs-box .left-image-box .upper-curve {
    position: absolute;
    right: -31px;
    top: 0px;
    width: 100px;
    height: 54.25%;
    background: #FFF;
    border-left: 10px solid #F4F4F4;
    -webkit-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    transform: rotate(15deg);
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
}

.tabs-box .left-image-box .lower-curve {
    position: absolute;
    right: -31px;
    bottom: 0px;
    width: 100px;
    height: 54.25%;
    background: #FFF;
    border-left: 10px solid #F4F4F4;
    -webkit-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    -o-transform: rotate(-15deg);
    -moz-transform: rotate(-15deg);
    transform: rotate(-15deg);
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
}

.tabs-box.tab-style-two .curves-outer {
    position: absolute;
    left: 113px;
    top: 0px;
    width: 110px;
    height: 100%;
    overflow: hidden;
}

.tabs-box.tab-style-two .upper-curve {
    position: absolute;
    left: 40px;
    top: 0px;
    width: 100px;
    height: 54.25%;
    background: #FFF;
    border-left: 1px solid #F0F0F0;
    -webkit-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    transform: rotate(15deg);
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
}

.tabs-box.tab-style-two .lower-curve {
    position: absolute;
    left: 40px;
    bottom: 0px;
    width: 100px;
    height: 54.25%;
    background: #FFF;
    border-left: 1px solid #F0F0F0;
    -webkit-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    -o-transform: rotate(-15deg);
    -moz-transform: rotate(-15deg);
    transform: rotate(-15deg);
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
}

.tabs-box h3 {
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 15px;
    color: #202E31;
    font-weight: 700;
}

.tabs-box .main-content-box .text {
    position: relative;
    margin-bottom: 30px;
    color: #A2A2A2;
    font-size: 14px;
}

.tabs-box .man-image-right {
    position: absolute;
    right: 40px;
    top: -80px;
}

.progress-boxes {
    position: relative;
}

.progress-boxes .progress-box {
    position: relative;
    margin-bottom: 15px;
}

.progress-boxes .bar-outer {
    position: relative;
    height: 20px;
    width: 100%;
    overflow: hidden;
    background: #EEE;
    color: #FFF;
    line-height: 20px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
}

.progress-boxes .bar-outer .bar-bg {
    position: absolute;
    left: 0px;
    top: 0px;
    margin-left: -10px;
    height: 100%;
    background: var(--color_main);
    -webkit-transform: skewX(-25deg);
    -ms-transform: skewX(-25deg);
    -o-transform: skewX(-25deg);
    -moz-transform: skewX(-25deg);
    transform: skewX(-25deg);
}

.progress-boxes .bar-outer .txt {
    position: relative;
    padding: 0px 18px;
}

.tabs-box .how-works-box .inner-content {
    position: relative;
    color: #FFF;
    padding: 50px 30px;
    background: var(--color_main);
    font-family: 'Poppins', sans-serif;
}

.tabs-box .styled-steps li {
    position: relative;
    padding: 22px 10px 23px 60px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.80);
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
}

.tabs-box .styled-steps li .count {
    position: absolute;
    left: 0px;
    top: 20px;
    width: 40px;
    height: 40px;
    line-height: 36px;
    text-align: center;
    border: 2px solid #FFF;
    border-radius: 50%;
}

.tabs-box .styled-steps li:last-child {
    border-bottom: none;
    padding-bottom: 0px;
}

.tabs-box .styled-steps li .small-text {
    font-size: 12px;
    display: block;
}

.what-we-do .image-section {
    position: absolute;
    left: 0px;
    bottom: 50px;
    z-index: 0;
    width: 100%;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #2A373B;
}

.what-we-do .image-section:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: #2A373B;
    opacity: 0.95;
}


/***

====================================================================
	Latest Work Section
====================================================================

***/

.latest-work {
    position: relative;
    padding: 90px 0px 100px;
}

.latest-work .owl-controls {
    display: none;
}

.latest-work .carousel-outer {
    position: relative;
    margin-bottom: 50px;
}

.default-work-column {
    position: relative;
}

.work-carousel .default-work-column {
    position: relative;
    margin-bottom: 10px;
}

.default-work-column .inner-box {
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid #E0E0E0;
    margin-left: -1px;
    margin-right: -1px;
}

.work-carousel .default-work-column:hover,
.projects-section .default-work-column:hover {
    z-index: 5;
    -webkit-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.10);
}

.default-work-column:hover .inner-box {
    border-color: #E0E0E0;
}

.default-work-column .image-box img {
    position: relative;
    display: block;
    width: 100%;
    height: 350px;
}

.default-work-column .overlay-link {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    color: #FFF;
    border-bottom: 120px solid transparent;
    text-align: center;
    background-color: rgba(32, 46, 49, 0.85);
    opacity: 0;
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    -moz-transform: scaleY(0);
    transform: scaleY(0);
}

.default-work-column:hover .overlay-link {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -moz-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1;
}

.default-work-column .overlay-link .icon {
    position: absolute;
    left: 0px;
    top: 50%;
    width: 100%;
    height: 50px;
    font-size: 36px;
    line-height: 50px;
    margin-top: -25px;
    text-align: center;
}

.default-work-column .caption-layer {
    position: absolute;
    left: 0px;
    bottom: -42px;
    width: 100%;
    height: auto;
    background: #FFF;
    border: 1px solid #E0E0E0;
    border-bottom: none;
    border-top: 5px solid var(--color_main);
    padding-bottom: 1px;
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
}

.default-work-column:hover .caption-layer {
    bottom: 0px;
}

.default-work-column .caption-layer .upper {
    position: relative;
    padding: 18px 20px 13px;
    text-align: center;
}

.default-work-column .caption-layer h3 {
    font-size: 16px;
    font-weight: 700;
    color: #202E31;
    text-transform: uppercase;
    margin-bottom: 0px;
}

.default-work-column .caption-layer h3 a {
    color: #202E31;
}

.default-work-column .caption-layer .category {
    font-size: 12px;
    color: #B2B2B2;
    text-transform: uppercase;
}

.default-work-column .caption-layer .category a {
    color: #B2B2B2;
}

.default-work-column .caption-layer .options {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    border-right: none;
    vertical-align: middle;
}

.default-work-column .caption-layer .options li {
    position: relative;
    float: left;
    font-size: 12px;
    line-height: 24px;
    padding: 6px 5px 6px 12px;
    color: #A6A6A6;
    text-transform: uppercase;
    vertical-align: middle;
    width: 50% !important;
    border: 1px solid #E0E0E0;
    border-left: none;
    border-bottom: none;
}

.default-work-column .caption-layer .options li a {
    color: #A6A6A6;
}

.default-work-column .caption-layer .options li .icon {
    position: relative;
    top: 1px;
    font-size: 20px;
    padding-right: 10px;
    color: #202E31;
    line-height: 24px;
}

.latest-work .more-link {
    display: inline-block;
    font-weight: 700;
    text-transform: uppercase;
    color: #202E31;
    line-height: 20px;
    font-family: 'Poppins', sans-serif;
    border-bottom: 1px solid #202E31;
}

/***

====================================================================
	Our Team Section
====================================================================

***/

.team-section {
    position: relative;
    padding: 90px 0px 50px;
    background-color: #202E31;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
}

.team-section:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    opacity: 0.95;
    background-color: #202E31;
}

.bg-light {
    background: #F5F5F5;
}

.bg-light:before {
    display: none;
}

.team-section .sec-title h2 {
    color: #FFF;
    -webkit-text-shadow: none;
    -ms-text-shadow: none;
    -o-text-shadow: none;
    -moz-text-shadow: none;
    text-shadow: none;
}

.bg-light.team-section .sec-title h2 {
    color: #202E31;
    -webkit-text-shadow: 5px 5px 1px #E5E5E5;
    -ms-text-shadow: 5px 5px 1px #E5E5E5;
    -o-text-shadow: 5px 5px 1px #E5E5E5;
    -moz-text-shadow: 5px 5px 1px #E5E5E5;
    text-shadow: 5px 5px 1px #E5E5E5;
}

.team-section .sec-title .text {
    color: #E0E0E0;
}

.bg-light.team-section .sec-title .text {
    color: #999;
}

.team-section .team-member {
    position: relative;
    margin-bottom: 50px;
}

.team-member .inner-box {
    position: relative;
    margin: 0 auto;
    overflow: hidden;
}

.team-member .inner-box .image-box {
    position: relative;
    text-align: center;
    z-index: 1;
}

.team-member .inner-box .image-box img {
    display: inline-block;
    max-width: 100%;
}

.team-member .lower-part {
    position: absolute;
    left: 0px;
    bottom: -39px;
    width: 100%;
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
}

.team-member:hover .lower-part {
    bottom: 0px;
}

.team-member .lower-part .left-curve {
    position: absolute;
    left: 0px;
    top: -21px;
    width: 53.1%;
    height: 90px;
    background: #FFF;
    border-top: 1px solid #E0E0E0;
    border-left: 1px solid #E0E0E0;
    -webkit-transform: rotate(-12deg);
    -ms-transform: rotate(-12deg);
    -o-transform: rotate(-12deg);
    -moz-transform: rotate(-12deg);
    transform: rotate(-12deg);
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    z-index: 2;
}

.team-member .lower-part .right-curve {
    position: absolute;
    right: 0px;
    top: -21px;
    width: 53.1%;
    height: 90px;
    background: #FFF;
    border-top: 1px solid #E0E0E0;
    border-right: 1px solid #E0E0E0;
    -webkit-transform: rotate(12deg);
    -ms-transform: rotate(12deg);
    -o-transform: rotate(12deg);
    -moz-transform: rotate(12deg);
    transform: rotate(12deg);
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    z-index: 2;
}

.team-member:hover .lower-part .right-curve,
.team-member:hover .lower-part .left-curve {
    border-top-color: var(--color_main);
    border-top-width: 5px;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
}

.team-member .caption-layer {
    position: relative;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: auto;
    background: #FFF;
    border: 1px solid #E0E0E0;
    border-top: none;
    padding-bottom: 1px;
    z-index: 2;
}

.team-member .caption-layer .upper {
    position: relative;
    padding: 0px 20px 13px;
    text-align: center;
    border-bottom: 1px solid #E0E0E0;
}

.team-member .caption-layer h3 {
    font-size: 16px;
    font-weight: 700;
    color: #202E31;
    text-transform: uppercase;
    margin-bottom: 0px;
}

.team-member .caption-layer h3 a {
    color: #202E31;
}

.team-member .caption-layer .category {
    font-size: 12px;
    color: #B2B2B2;
    text-transform: uppercase;
}

.team-member .caption-layer .category a {
    color: #B2B2B2;
}

.team-member .caption-layer .options {
    position: relative;
    display: table;
    width: 100%;
    height: auto;
    border-right: none;
    vertical-align: middle;
}

.team-member .caption-layer .options li {
    position: relative;
    display: table-cell;
    font-size: 11px;
    line-height: 24px;
    padding: 6px 15px;
    color: #A6A6A6;
    text-transform: uppercase;
    vertical-align: middle;
    border-right: 1px solid #E0E0E0;
}

.team-member .caption-layer .options li:last-child {
    border-right: none;
}

.team-member .caption-layer .options li a {
    color: #A6A6A6;
}

.team-member .caption-layer .options li .icon {
    position: relative;
    top: 1px;
    font-size: 16px;
    padding-right: 10px;
    color: #202E31;
    line-height: 24px;
}

.team-member .bg-layer {
    position: absolute;
    left: 10%;
    bottom: 0px;
    height: 250px;
    width: 80%;
    background: #2F4448;
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
}

.team-member:hover .bg-layer {
    background: none;
}

.team-member .bg-layer:before {
    content: '';
    position: absolute;
    right: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: var(--color_main) url(../images/background/bg-pattern.png) right top repeat-y;
    opacity: 0;
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transform: skewY(0deg);
    -ms-transform: skewY(0deg);
    -o-transform: skewY(0deg);
    -moz-transform: skewY(0deg);
    transform: skewY(0deg);
}

.team-member:hover .bg-layer:before {
    opacity: 1;
    -webkit-transform: skewY(-10deg);
    -ms-transform: skewY(-10deg);
    -o-transform: skewY(-10deg);
    -moz-transform: skewY(-10deg);
    transform: skewY(-10deg);
}


/***

====================================================================
	Testimonials Section
====================================================================

***/

.testimonials-section {
    position: relative;
    padding: 90px 0px 100px;
    background-color: #FFF;
}

.testimonials-section .carousel-outer {
    position: relative;
}

.testimonials-section .testi-box {
    position: relative;
    padding-left: 150px;
    min-height: 152px;
    border: 1px solid #E0E0E0;
}

.testimonials-section .testi-box:hover {
    border-color: var(--color_main);
}

.testimonials-section .testi-box .text-content {
    position: relative;
    padding: 20px 20px 10px;
    font-style: italic;
    color: #999;
    line-height: 23px;
    font-size: 14px;
}

.testimonials-section .testi-box .author-thumb {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 150px;
    height: 150px;
    overflow: hidden;
}

.testimonials-section .testi-box .author-thumb img {
    display: block;
    width: 100%;
}

.testimonials-section .testi-box .info-box {
    position: absolute;
    left: 0px;
    bottom: -1px;
    width: 100%;
    background: #202E31;
    text-align: center;
}

.testimonials-section .testi-box .info-box .left-curve {
    position: absolute;
    left: 0px;
    top: -15px;
    width: 56%;
    height: 50px;
    background: #202E31;
    border-top: 5px solid transparent;
    -webkit-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    -o-transform: rotate(-15deg);
    -moz-transform: rotate(-12deg);
    transform: rotate(-15deg);
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
}

.testimonials-section .testi-box .info-box .right-curve {
    position: absolute;
    right: 0px;
    top: -15px;
    width: 56%;
    height: 50px;
    background: #202E31;
    border-top: 5px solid transparent;
    -webkit-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    transform: rotate(15deg);
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
}

.testimonials-section .testi-box .info-box .content {
    position: relative;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: auto;
    background: #202E31;
    line-height: 18px;
    padding-bottom: 3px;
}

.testimonials-section .testi-box .info-box .content h4 {
    font-size: 13px;
    font-weight: 700;
    color: #FFF;
    text-transform: uppercase;
    margin-bottom: 0px;
    line-height: 14px;
}

.testimonials-section .testi-box .info-box .content .designation {
    font-size: 10px;
    color: #CCC;
    text-transform: uppercase;
}

.testimonials-section .owl-controls {
    margin: 0px;
    padding: 0px;
}

.testimonials-section .owl-dots {
    display: none !important;
}

.testimonials-section .testimonials-carousel {
    position: static;
}

.testimonials-section .carousel-outer .owl-prev {
    position: absolute;
    left: 0px !important;
    top: 50% !important;
    margin: -20px 0px 0px 0px !important;
    border-radius: 0px !important;
    width: 40px;
    height: 40px;
    padding: 0px !important;
    background: #4D585A !important;
    color: #FFF;
    line-height: 40px;
}

.testimonials-section .carousel-outer .owl-next {
    position: absolute;
    right: 0px !important;
    top: 50% !important;
    margin: -20px 0px 0px 0px !important;
    border-radius: 0px !important;
    width: 40px;
    height: 40px;
    padding: 0px !important;
    background: #4D585A !important;
    color: #FFF;
    line-height: 40px;
}

.testimonials-section .carousel-outer .owl-prev:hover,
.testimonials-section .carousel-outer .owl-next:hover {
    background: var(--color_main) !important;
}

/***

====================================================================
	Latest News Section
====================================================================

***/

.latest-news {
    position: relative;
    padding: 90px 0px;
    background-color: #FFF;
    overflow: hidden;
}

.latest-news .outer-box {
    position: relative;
    margin-left: 120px;
    padding: 90px 15px 80px;
    padding-left: 160px;
    background: #202E31 url(../images/background/bg-scale.png) left bottom repeat-x;
    z-index: 1;
}

.latest-news .outer-box:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 0px;
    width: 1500px;
    height: 100%;
    background: #202E31 url(../images/background/bg-scale.png) left bottom repeat-x;
}

.latest-news .outer-box .left-image {
    position: absolute;
    left: -120px;
    top: -80px;
}

.latest-news .outer-box .sec-title h2 {
    color: #FFF;
    -webkit-text-shadow: none;
    -ms-text-shadow: none;
    -o-text-shadow: none;
    -moz-text-shadow: none;
    text-shadow: none;
}

.latest-news .outer-box .sec-title .text {
    color: #E0E0E0;
}

.latest-news .outer-box .post-item {
    margin-bottom: 40px;
}

.latest-news .outer-box .curve-box {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 10px;
    background: #202E31;
}

.latest-news .outer-box .curve-box .left-curve {
    position: absolute;
    left: 0px;
    top: -7px;
    width: 59%;
    height: 50px;
    background: #202E31;
    border-top: 5px solid #FFF;
    -webkit-transform: rotate(-20deg);
    -ms-transform: rotate(-20deg);
    -o-transform: rotate(-20deg);
    -moz-transform: rotate(-20deg);
    transform: rotate(-20deg);
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
}

.latest-news .outer-box .curve-box .right-curve {
    position: absolute;
    right: 0px;
    top: -7px;
    width: 59%;
    height: 50px;
    background: #202E31;
    border-top: 5px solid #FFF;
    -webkit-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    -o-transform: rotate(20deg);
    -moz-transform: rotate(20deg);
    transform: rotate(20deg);
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
}

.latest-news .outer-box .post-item:hover .curve-box .left-curve,
.latest-news .outer-box .post-item:hover .curve-box .right-curve,
.latest-news .outer-box .post-item:hover .post-thumb {
    border-color: var(--color_main);
    border-top-color: var(--color_main);
}

.latest-news .outer-box .post-item .post-thumb {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 130px;
    border: 5px solid #FFF;
    border-bottom: none;
    overflow: hidden;
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
}

.latest-news .outer-box .post-item .post-thumb img {
    display: block;
    width: 100%;
}

.latest-news .outer-box .post-item h4 {
    font-size: 16px;
    font-weight: 700;
    color: #FFF;
    line-height: 20px;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.latest-news .outer-box .post-item h4 a {
    color: #FFF;
}

.latest-news .outer-box .post-item .time {
    color: #969696;
    font-size: 12px;
    text-transform: uppercase;
}

.latest-news .outer-box .post-item .inner-box {
    position: relative;
    max-width: 390px;
    padding-left: 150px;
    padding-top: 15px;
}

/***

====================================================================
	Sponsors Slider Style
====================================================================

***/

.sponsors-section {
    position: relative;
    padding: 90px 0px;
}

.sponsors-outer .owl-controls {
    display: none;
}

.sponsors-carousel .slide-item {
    position: relative;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
    margin: 1px;
    text-align: center;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}

.sponsors-carousel .slide-item:hover {
    border-color: #999;
}

.sponsors-carousel .slide-item img {
    display: block;
    max-width: 100%;
    width: 100%;
    opacity: 1;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}


/***

====================================================================
	Main Footer
====================================================================

***/

.main-footer {
    position: relative;
    color: #72868A;
    background: var(--color_main);
}

.main-footer .footer-upper {
    position: relative;
    padding: 80px 0px 20px;
    color: #FFFFFF;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.main-footer .footer-upper:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    opacity: 0.97;
    background: rgb(63, 120, 134);
}

.main-footer .footer-upper .auto-container {
    position: relative;
    z-index: 10;
}

.main-footer .footer-upper .column {
    position: relative;
    margin-bottom: 40px;
}

.footer-upper .column h3 {
    position: relative;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #FFF;
}

.main-footer .about-widget {
    font-size: 14px;
    color: #FFF;
    line-height: 2em;
    word-spacing: 2px;
    padding-right: 10px;
}

.main-footer .footer-upper a {
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.main-footer .footer-upper a:hover {
    color: #FFF;
}

.footer-upper .links-widget li {
    position: relative;
    margin-bottom: 15px;
}

.footer-upper .links-widget li a {
    position: relative;
    display: block;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 20px;
}

.footer-upper .links-widget li a:hover {
    color: var(--color_main);
    text-decoration: underline;
}

.main-footer .social-links a {
    position: relative;
    display: inline-block;
    font-size: 13px;
    width: 32px;
    height: 32px;
    line-height: 30px;
    border: 1px solid #72868A;
    text-align: center;
    margin: 0px 5px 10px 0px;
    color: #FFF;
    background: none;
    border-radius: 50%;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.main-footer .social-links a:hover {
    color: #101010;
    background: var(--color_main);
    border-color: var(--color_main);
}

.main-footer .contact-info {
    font-size: 14px;
    margin-bottom: 20px;
}

.main-footer .contact-info li {
    position: relative;
    margin-bottom: 8px;
    padding-left: 10px;
    line-height: 24px;
}

.main-footer .contact-info li strong {
    position: absolute;
    left: 0px;
    top: 0px;
    font-weight: 700;
    text-transform: uppercase;
}

.main-footer .contact-info li a {
    color: #72868A;
}

.footer-bottom {
    position: relative;
    padding: 20px 0px;
    line-height: 30px;
    color: #738D93;
    background: #172224;
    font-size: 14px;
}

.footer-bottom a {
    color: #738D93;
}

.footer-bottom a:hover {
    color: #FFF;
}

/***

====================================================================
	Fun Facts Section
====================================================================

***/

.fun-facts {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
}

.fun-facts:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(253, 194, 54, 0.95);
}

.fun-facts .content-box {
    position: relative;
    padding: 40px 80px 15px 50px;
}

.fun-facts .content-box:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: #FFF;
    border: 1px solid #E0E0E0;
    border-left: none;
    border-right: none;
    -webkit-transform: skewX(-25deg);
    -ms-transform: skewX(-25deg);
    -o-transform: skewX(-25deg);
    -moz-transform: skewX(-25deg);
    transform: skewX(-25deg);
}

.fun-facts .counter-column {
    position: relative;
    margin-bottom: 20px;
}

.fun-facts .count-outer {
    text-transform: uppercase;
    font-size: 18px;
    color: #303030;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}

.fun-facts .counter-column .icon-box {
    position: relative;
    font-size: 30px;
    margin-bottom: 15px;
    color: #202E31;
}

/***

====================================================================
	Gallery Section
====================================================================

***/

.gallery-section {
    position: relative;
}

.gallery-section .filters {
    position: relative;
    text-align: center;
    margin-bottom: 40px;
}

.gallery-section .filters {
    position: relative;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

.gallery-section .filters li {
    position: relative;
    display: inline-block;
    padding: 5px 25px;
    line-height: 30px;
    color: #202E31;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    min-width: 100px;
    margin: 0px 10px;
    transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
}

.gallery-section .filters li .txt {
    position: relative;
    z-index: 2;
}

.gallery-section .filters li:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    border: 1px solid #E0E0E0;
    background: #FFF;
    -webkit-transform: skewX(-25deg);
    -ms-transform: skewX(-25deg);
    -o-transform: skewX(-25deg);
    -moz-transform: skewX(-25deg);
    transform: skewX(-25deg);
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
}

.gallery-section .filters li:hover:before,
.gallery-section .filters li.active:before {
    background: var(--color_main);
}

.gallery-section .filters li:hover,
.gallery-section .filters li.active {
    color: #FFF;
}


.default-portfolio-item {
    position: relative;
}

.sortable-masonry .column {
    padding: 0px 15px 0px;
    margin-bottom: 30px;
}

.default-portfolio-item .inner-box {
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid #E0E0E0;
    margin-left: -1px;
    margin-right: -1px;
}

.default-portfolio-item .inner-box:hover {
    z-index: 5;
    -webkit-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.10);
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.10);
}

.default-portfolio-item:hover .inner-box {
    border-color: #E0E0E0;
}

.default-portfolio-item .image-box img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
}

.default-portfolio-item .overlay-link {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    color: #FFF;
    border-bottom: 70px solid transparent;
    text-align: center;
    background-color: rgba(32, 46, 49, 0.85);
    opacity: 0;
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    -moz-transform: scaleY(0);
    transform: scaleY(0);
}

.default-portfolio-item:hover .overlay-link {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -moz-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1;
}

.default-portfolio-item .overlay-link .icon {
    position: absolute;
    left: 0px;
    top: 50%;
    width: 100%;
    height: 50px;
    font-size: 36px;
    line-height: 50px;
    margin-top: -25px;
    text-align: center;
}

.default-portfolio-item .caption-layer {
    position: absolute;
    left: 0px;
    bottom: -150px;
    width: 100%;
    height: auto;
    background: #FFF;
    border: 1px solid #E0E0E0;
    border-bottom: none;
    border-top: 5px solid var(--color_main);
    padding-bottom: 1px;
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
}

.default-portfolio-item:hover .caption-layer {
    bottom: 0px;
}

.default-portfolio-item .caption-layer .upper {
    position: relative;
    padding: 15px 15px 10px;
    text-align: center;
}

.default-portfolio-item .caption-layer h3 {
    font-size: 16px;
    font-weight: 700;
    color: #202E31;
    text-transform: uppercase;
    margin-bottom: 0px;
}

.default-portfolio-item .caption-layer h3 a {
    color: #202E31;
}

.default-portfolio-item .caption-layer .category {
    font-size: 12px;
    color: #B2B2B2;
    text-transform: uppercase;
}

.default-portfolio-item .caption-layer .category a {
    color: #B2B2B2;
}

/***

====================================================================
	Page Title Section
====================================================================

***/

.page-title {
    position: relative;
    padding: 120px 0px 50px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    margin-top: 0px;
    color: #FFF;
    text-align: center;
}

.page-title:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(32, 46, 49, 0.85);
}

.page-title:after {
    content: '';
    position: absolute;
    left: 0px;
    bottom: 0px;
    display: block;
    width: 100%;
    height: 13px;
}

.page-title .auto-container {
    position: relative;
    z-index: 1;
}

.page-title h1 {
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 15px;
    font-weight: 700;
    color: #FFF;
}

.page-title .text {
    color: #FFF !important;
    line-height: 2em;
}

.theme-breadcrumb {
    position: relative;
    padding: 60px 0px 20px;
}

.theme-breadcrumb .breadcrumb {
    background: none;
    padding-left: 0px;
}

.theme-breadcrumb .breadcrumb li,
.theme-breadcrumb .breadcrumb li a {
    color: #000000;
    font-size: 12px;
    text-transform: uppercase;
}

/***

====================================================================
	Default Two COlumn
====================================================================

***/

.default-two-column {
    position: relative;
}

.default-two-column .column {
    position: relative;
    margin-bottom: 40px;
}

.default-two-column .skewed-image {
    position: relative;
}

.default-two-column .image-box {
    position: relative;
    display: block;
    width: auto;
    margin-right: 20px;
}

.default-two-column .image-box:before {
    content: '';
    position: absolute;
    width: 300px;
    height: 100%;
    top: 10px;
    right: 40px;
    background: var(--color_main);
    -webkit-transform: skewX(-15deg);
    -ms-transform: skewX(-15deg);
    -o-transform: skewX(-15deg);
    -moz-transform: skewX(-15deg);
    transform: skewX(-15deg);
}

.default-two-column .image-box img {
    position: relative;
    display: block;
    width: 100%;
    z-index: 1;
}

.default-two-column .text-column .text {
    font-size: 16px;
}

/***

====================================================================
	Services Section
====================================================================

***/

.services-section {
    position: relative;
    padding: 20px 0px 100px;
}

.services-section .outer-box {
    position: relative;
    padding: 50px 50px;
    color: #FFF;
    background: #202E31;
}

.services-section .outer-box .man-image {
    position: absolute;
    right: -130px;
    bottom: 0px;
}

.services-section .column {
    position: relative;
    margin-bottom: 40px;
}

.services-section h3 {
    color: var(--color_main);
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.services-section .text {
    color: #FFFFFF;
    font-size: 16px;
    line-height: 2em;
}

/***

====================================================================
	Projects Section
====================================================================

***/

.projects-section {
    position: relative;
    padding: 90px 0px 100px;
    overflow: hidden;
}

.projects-section .default-work-column {
    position: relative;
    float: left;
    width: 25%;
}

/***

====================================================================
	Single Projects Section
====================================================================

***/

.single-project {
    position: relative;
    padding: 90px 0px 100px;
}

.single-project .upper-part {
    position: relative;
    margin-bottom: 30px;
}

.single-project .upper-part .upper-box {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
}

.single-project .upper-part .main-image {
    position: relative;
    margin-right: 350px;
}

.single-project .upper-part .main-image img {
    display: block;
    width: 100%;
}

.single-project .upper-part .info-box {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    max-width: 350px;
    padding: 50px 40px 20px;
    color: #FFF;
    background: #202E31;
}

.single-project .upper-part .info-box:before {
    content: '';
    position: absolute;
    left: -35px;
    top: 0px;
    width: 80px;
    height: 100%;
    background: #202E31;
    -webkit-transform: skewX(-10deg);
    -ms-transform: skewX(-10deg);
    -o-transform: skewX(-10deg);
    -moz-transform: skewX(-10deg);
    transform: skewX(-10deg);
    z-index: 1;
}

.single-project .upper-part .info-box .white-bar {
    position: absolute;
    left: -35px;
    top: 0px;
    width: 20px;
    height: 100%;
    background: #FFF;
    -webkit-transform: skewX(-10deg);
    -ms-transform: skewX(-10deg);
    -o-transform: skewX(-10deg);
    -moz-transform: skewX(-10deg);
    transform: skewX(-10deg);
    z-index: 4;
}

.single-project .upper-part .info-box:after {
    content: '';
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 400px;
    height: 40px;
    background: #202E31 url(../images/background/bg-ruler.png) right bottom repeat-x;
    z-index: 2;
}

.single-project .upper-part .info-box h4 {
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 20px;
    color: var(--color_main);
    font-weight: 700;
    z-index: 5;
}

.single-project .upper-part .info-box ul {
    position: relative;
    z-index: 5;
}

.single-project .upper-part .info-box ul li {
    margin-bottom: 10px;
    line-height: 24px;
    color: #9FB0B4;
    font-size: 14px;
}

.single-project .upper-part .info-box ul li strong {
    display: block;
    float: left;
    width: 40%;
    font-size: 14px;
    text-transform: uppercase;
    color: #FFF;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}

.single-project .upper-part .info-box ul li .data {
    display: block;
    float: left;
    width: 60%;
}

.single-project .upper-part .lower-box {
    position: relative;
}

.single-project .upper-part .lower-box .related-thumbs {
    position: relative;
}

.single-project .upper-part .lower-box .related-thumbs .thumb {
    float: left;
    margin-right: 15px;
    margin-bottom: 20px;
}

.single-project .upper-part .lower-box .related-thumbs .thumb img {
    display: block;
    max-width: 100%;
}

.single-project .text-column h3 {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #202E31;
}

.single-project .text-column p {
    margin-bottom: 20px;
}


/***

====================================================================
	Sidebar Page Style
====================================================================

***/

.sidebar-page-container {
    position: relative;
    padding: 0px 0px 100px;
    background-color: #F2F2F2;
}

.sidebar-widget {
    position: relative;
    background: #FFF;
    margin-bottom: 30px;
}

.sidebar-title {
    position: relative;
    padding: 20px 25px;
    border-bottom: 1px solid #E0E0E0;
}

.sidebar-title:before {
    content: '';
    position: absolute;
    left: 25px;
    bottom: -1px;
    height: 1px;
    width: 150px;
    background: var(--color_main);
}

.sidebar-title h3 {
    font-size: 18px;
    text-transform: uppercase;
    color: #202E31;
    font-weight: 700;
    line-height: 26px;
}

.sidebar-widget hr {
    margin: 10px 0px 15px;
}

.sidebar-widget .widget-inner {
    position: relative;
    padding: 25px 20px;
    background: #FFF;
}

.sidebar .search-widget {
    position: relative;
}

.sidebar .search-widget .form-group {
    position: relative;
    margin: 0px;
}

.sidebar .search-widget .form-group input[type="text"],
.sidebar .search-widget .form-group input[type="search"] {
    display: block;
    width: 100%;
    line-height: 24px;
    padding: 10px 50px 10px 15px;
    border: 1px solid #F1F1F1;
    font-size: 13px;
    background: #F3F3F3;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    transition: all 500ms ease;
}

.sidebar .search-widget .form-group input[type="text"]:focus,
.sidebar .search-widget .form-group input[type="search"]:focus {
    border-color: var(--color_main);
}

.sidebar .search-widget .form-group button {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 50px;
    height: 45px;
    line-height: 42px;
    text-align: center;
    font-size: 14px;
    background: var(--color_main);
    color: #FFF;
}

.sidebar .links-widget ul {
    font-family: 'Poppins', sans-serif;
}

.sidebar .links-widget ul li {
    position: relative;
}

.sidebar .links-widget ul li:before {
    content: '\f105';
    font-family: 'FontAwesome';
    position: absolute;
    left: 0px;
    top: 4px;
    width: 15px;
    line-height: 24px;
    display: block;
    color: #2B3338;
}

.sidebar .links-widget ul li:last-child {
    border: none;
}

.sidebar .links-widget ul li a {
    position: relative;
    display: block;
    line-height: 24px;
    padding: 5px 0px 5px 20px;
    color: #202E31;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}

.sidebar .links-widget ul li a:hover {
    color: var(--color_main);
}

.sidebar .news-widget .news-item {
    position: relative;
    padding-left: 80px;
    margin-bottom: 25px;
    min-height: 65px;
}

.sidebar .news-widget .news-item .post-thumb {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 66px;
}

.sidebar .news-widget .news-item .post-thumb:after {
    content: '';
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 66px;
    border: 33px solid transparent;
    border-bottom: 10px solid #FFF;
}

.sidebar .news-widget .news-item .post-thumb img {
    display: block;
    width: 100%;
}

.sidebar .news-widget .news-item:last-child {
    margin-bottom: 0px;
}

.sidebar .news-widget .news-item h4 {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0px;
    color: #202E31;
    line-height: 18px;
    max-width: 200px;
}

.sidebar .news-widget .news-item h4 a {
    color: #202E31;
}

.sidebar .news-widget .news-item h4 a:hover {
    color: var(--color_main);
}

.sidebar .news-widget .news-item .time {
    color: #A4A4A4;
    font-size: 12px;
}

.comments-container {
    position: relative;
    margin-bottom: 50px;
    background: #FFF;
}

.content-side .sidebar-title h3 {
    font-size: 18px;
}

.comments-container .inner-box {
    padding: 0px 25px;
}

.comments-container .comment-box {
    position: relative;
    padding: 40px 0px 20px;
    border-bottom: 1px solid #E5E5E5;
}

.comments-container .comment-box:last-child {
    border-bottom: none;
}

.comments-container .comment-box .inner {
    position: relative;
    padding-left: 90px;
    min-height: 80px;
    font-size: 14px;
    color: #6B6B6B;
}

.comments-container .comment-box .inner .image {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 70px;
}

.comments-container .comment-box .inner .image:after {
    content: '';
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    border: 35px solid transparent;
    border-bottom: 10px solid #FFF;
}

.comments-container .comment-box .inner .image img {
    display: block;
    width: 100%;
}

.comments-container .comment-box .author-title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: #202E31;
    font-family: 'Poppins', sans-serif;
}

.comments-container .comment-box .time {
    text-transform: capitalize;
    color: #A4A4A4;
    font-size: 12px;
    margin-bottom: 10px;
}

.comments-container .comment-box .text {
    color: #6D6D6D;
    margin-bottom: 10px;
}

.comments-container .comment-box .reply-btn {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: #202E31;
    font-family: 'Poppins', sans-serif;
}

.comments-form-outer {
    position: relative;
    background: #FFF;
}

.comments-form-outer .inner-box {
    position: relative;
    padding: 25px 25px;
}

.comments-form-outer .form-group {
    position: relative;
    margin-bottom: 22px;
}

.comments-form-outer .form-group input[type="text"],
.comments-form-outer .form-group input[type="email"],
.comments-form-outer .form-group select,
.comments-form-outer .form-group textarea {
    display: block;
    width: 100%;
    line-height: 24px;
    padding: 15px 15px;
    border: 1px solid #E0E0E0;
    height: 56px;
    font-size: 13px;
    background: #FFF;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    transition: all 500ms ease;
}

.comments-form-outer .form-group input[type="text"]:focus,
.comments-form-outer .form-group input[type="email"]:focus,
.comments-form-outer .form-group select:focus,
.comments-form-outer .form-group textarea:focus {
    border-color: var(--color_main);
    color: var(--color_main);
}

.comments-form-outer .form-group textarea {
    resize: none;
    height: 150px;
}

.comments-form-outer button.theme-btn {
    font-size: 14px;
    font-weight: 700;
    padding: 12px 30px;
}

/***

====================================================================
	Default Blog Style
====================================================================

***/

.blog-page-body {
    background: #F1F1F1;
}

.blog-posts-container {
    position: relative;
}

.default-blog-post {
    position: relative;
    margin-bottom: 50px;
}

.default-blog-post .inner-box {
    position: relative;
    border: 1px solid #E0E0E0;
    margin: 0 auto;
    background: #FFF;
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
}

.default-blog-post .inner-box:hover {
    -webkit-box-shadow: 0px 0px 7px 5px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0px 0px 7px 5px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0px 0px 7px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 7px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 7px 5px rgba(0, 0, 0, 0.05);
}

.default-blog-post .inner-most {
    position: relative;
    overflow: hidden;
}

.default-blog-post .image-box {
    position: relative;
}

.default-blog-post .image-box img {
    display: block;
    width: 100%;
}

.default-blog-post .lower-part {
    position: relative;
    padding-top: 20px;
    padding-bottom: 10px;
    margin-top: -20px;
}

.default-blog-post .lower-part .left-curve {
    position: absolute;
    left: 0px;
    top: -30px;
    width: 52%;
    height: 90px;
    background: #FFF;
    border-top: 7px solid #F1F1F1;
    -webkit-transform: rotate(-12deg);
    -ms-transform: rotate(-12deg);
    -o-transform: rotate(-12deg);
    -moz-transform: rotate(-12deg);
    transform: rotate(-12deg);
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
}

.default-blog-post .lower-part .right-curve {
    position: absolute;
    right: 0px;
    top: -30px;
    width: 52%;
    height: 90px;
    background: #FFF;
    border-top: 7px solid #F1F1F1;
    -webkit-transform: rotate(12deg);
    -ms-transform: rotate(12deg);
    -o-transform: rotate(12deg);
    -moz-transform: rotate(12deg);
    transform: rotate(12deg);
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
}

.default-blog-post:hover .lower-part .left-curve,
.default-blog-post:hover .lower-part .right-curve {
    border-top-color: var(--color_main);
}

.two-column .default-blog-post .lower-part .left-curve,
.two-column .default-blog-post .lower-part .right-curve {
    top: -10px;
}

.default-blog-post .content {
    position: relative;
    padding: 20px 40px 30px;
    z-index: 1;
}

.two-column .default-blog-post .content {
    padding: 20px 30px 20px;
}

.default-blog-post .content h3 {
    position: relative;
    text-transform: uppercase;
    font-size: 24px;
    margin-bottom: 0px;
    color: #202E31;
    font-weight: 700;
}

.blog-posts-container.two-column .default-blog-post .content h3 {
    font-size: 20px;
}

.default-blog-post .content .author-info {
    color: #202E31;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-size: 12px;
}

.two-column .default-blog-post .content .author-info {
    margin-bottom: 10px;
}

.default-blog-post .content h3 a,
.default-blog-post .content .author-info a {
    color: #202E31;
}

.default-blog-post .content h3 a:hover,
.default-blog-post .content .author-info a:hover {
    color: var(--color_main);
}

.default-blog-post .content .text {
    position: relative;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 30px;
    color: #8E8E8E;
}

.default-blog-post .content .more-link {
    position: relative;
}

.default-blog-post .content .read-more {
    position: relative;
    display: inline-block;
    line-height: 20px;
    font-weight: 700;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    border-bottom: 1px solid transparent;
    color: #202E31;
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
}

.default-blog-post .content .read-more:hover {
    color: var(--color_main);
    border-color: var(--color_main);
}

.default-blog-post .date-box {
    position: absolute;
    left: 50%;
    top: -95px;
    margin-left: -30px;
    width: 60px;
    height: 60px;
    padding: 12px 5px 5px;
    text-align: center;
    color: #FFF;
    background: var(--color_main);
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
}

.two-column .default-blog-post .date-box {
    top: -55px;
}

.default-blog-post .date-box .day {
    font-size: 24px;
}

.blog-details .content .text {
    font-size: 14px;
}

.blog-details .content .text p {
    margin-bottom: 20px;
}

.blog-details .content .text p:last-child {
    margin-bottom: 0px;
}

.blog-details blockquote {
    border-left: 6px solid var(--color_main);
    padding: 15px 20px;
    background: #FFF;
    font-size: 14px;
}

.blog-details .post-info {
    position: relative;
    border-top: 1px solid #E0E0E0;
}

.blog-details .post-info li {
    display: inline-block;
    padding: 10px 25px;
    line-height: 24px;
    text-transform: uppercase;
    color: #8E8E8E;
    font-size: 12px;
    border-right: 1px solid #E0E0E0;
}

.blog-details .post-info li:last-child {
    border: none;
}

.blog-details .post-info li .icon {
    line-height: 24px;
    font-size: 16px;
    padding-right: 5px;
}

.blog-details .post-info li a {
    color: #8E8E8E;
}

.blog-details .post-info li strong {
    padding-right: 5px;
    font-family: 'Poppins', sans-serif;
}

/***

====================================================================
	Styled Pagination
====================================================================

***/

.styled-pagination {
    position: relative;
    font-family: 'Poppins', sans-serif;
}

.styled-pagination li {
    position: relative;
    display: inline-block;
    margin: 0px 3px 10px 0px;
}

.styled-pagination li a {
    position: relative;
    display: block;
    line-height: 24px;
    padding: 6px 5px 4px;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    min-width: 40px;
    border: 2px solid rgba(0, 0, 0, 0);
    background: #FFF;
    color: #1A1A1A;
    text-align: center;
    border-radius: 3px;
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
}

.styled-pagination li a .fa {
    line-height: 24px;
}

.styled-pagination li a:hover,
.styled-pagination li a.active {
    color: var(--color_main);
    border-color: var(--color_main);
}

/***

====================================================================
	Contact Section
====================================================================

***/

.contact-section {
    position: relative;
    padding: 0px;
    margin-bottom: 100px;
}

.contact-section .map-container {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    border-top: 66px solid transparent;
    border-bottom: 64px solid transparent;
}

.map-container .map-canvas {
    position: relative;
    width: 100%;
}

.map-container .map-canvas .map-data {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}

.map-container .map-canvas .map-data h6 {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    text-align: center;
    margin-bottom: 5px;
    color: #181818;
    font-family: 'Poppins', sans-serif;
}

.contact-section .contact-form-outer {
    position: relative;
    background: #FFF;
    max-width: 700px;
}

.contact-section .contact-form-outer .inner-box {
    position: relative;
    padding: 40px 30px;
}

.contact-section .contact-form-outer .row {
    margin-left: -10px;
    margin-right: -10px;
}

.contact-section .contact-form-outer .form-group {
    position: relative;
    margin-bottom: 20px;
    padding: 0px 10px !important;
}

.contact-section .contact-form-outer .form-group label.error {
    display: block;
    line-height: 20px;
    padding: 10px 0px 0px;
    color: #F00;
    font-weight: normal;
    font-size: 12px;
    text-transform: uppercase;
}

.contact-section .contact-form-outer .form-group input[type="text"],
.contact-section .contact-form-outer .form-group input[type="email"],
.contact-section .contact-form-outer .form-group select,
.contact-section .contact-form-outer .form-group textarea {
    display: block;
    width: 100%;
    line-height: 24px;
    padding: 14px 15px;
    border: 1px solid #E0E0E0;
    height: 54px;
    font-size: 13px;
    background: #FFF;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    transition: all 500ms ease;
}

.contact-section .contact-form-outer .form-group input[type="text"]:focus,
.contact-section .contact-form-outer .form-group input[type="email"]:focus,
.contact-section .contact-form-outer .form-group select:focus,
.contact-section .contact-form-outer .form-group textarea:focus {
    border-color: var(--color_main);
    color: var(--color_main);
}

.contact-section .contact-form-outer .form-group input.error,
.contact-section .contact-form-outer .form-group select.error,
.contact-section .contact-form-outer .form-group textarea.error {
    border-color: #F00;
    color: #F00;
}

.contact-section .contact-form-outer .form-group textarea {
    resize: none;
    height: 150px;
}

.contact-section .contact-form-outer button.theme-btn {
    font-size: 14px;
    font-weight: 700;
    padding: 14px 35px;
}

.contact-section .contact-info {
    position: relative;
    background: #142326;
    padding: 10px 20px 0px;
    color: #FFF;
}

.contact-section .contact-info .column {
    position: relative;
    float: left;
    min-width: 80px;
    margin: 0px 25px 10px;
}

.contact-section .contact-info .info-box {
    position: relative;
    padding-left: 45px;
}

.contact-section .contact-info .info-box .icon-box {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 40px;
    line-height: 42px;
    color: #FFF;
    font-size: 26px;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.contact-section .contact-info .info-box:hover .icon-box {
    color: var(--color_main);
}

.contact-section .contact-info .info-box li {
    position: relative;
    line-height: 20px;
    margin: 0px 0px;
    color: #FFF;
    text-transform: uppercase;
}

.contact-section .contact-info .info-box li .small-title {
    font-size: 10px;
    letter-spacing: 1px;
}

.contact-section .contact-info .info-box li a {
    color: #FFF;
}

.contact-section .contact-info .info-box strong {
    font-weight: 700;
    color: #FFF;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
}

/***

====================================================================
	Error Section
====================================================================

***/

.error-section {
    position: relative;
    padding: 0px 0px 100px;
}

.error-section .image-column {
    position: relative;
}

.error-section .image-column img {
    display: inline-block;
    max-width: 100%;
}

.error-section .text-column {
    position: relative;
}

.error-section .text-column h2 {
    font-size: 84px;
    margin: 70px 0px 20px;
    line-height: 90px;
    color: #202E31;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    -webkit-text-shadow: 5px 6px 1px #E7E8E8;
    -ms-text-shadow: 5px 6px 1px #E7E8E8;
    -o-text-shadow: 5px 6px 1px #E7E8E8;
    -moz-text-shadow: 5px 6px 1px #E7E8E8;
    text-shadow: 5px 6px 1px #E7E8E8;
}

.error-section .text-column h4 {
    font-size: 18px;
    margin-bottom: 30px;
    line-height: 30px;
    color: #ACACAC;
    font-weight: 700;
    text-transform: uppercase;
}
